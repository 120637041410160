import React from "react";


const RequiredMessage = () => {
	return (
		<p style={{
			fontStyle: "italic",
			fontSize: "0.7rem",
			marginRight: "auto",
			marginBottom: 20
		}}>Les champs marqués d'un <span className={"error-static"}>*</span> sont obligatoires.</p>
	)
}

export default RequiredMessage;