import "../form.css";
import { Link } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import authService from "../../../services/client/clientService";
import { AuthInput } from "../Input/AuthInput";
import { Icon } from "../../Icons/Icon";
import FormSubmitButton from "../Input/FormSubmitButton";
import React from "react";
import RequiredMessage from "./RequiredMessage";

const Login_Form = () => {
	const schema = yup
		.object({
			email: yup.string().email("Invalid email.").required("Email is required."),
			password: yup.string().required("Password is required.")
		})
		.required();

	const {
		control,
		handleSubmit,
		formState: { errors }
	} = useForm({
		resolver: yupResolver(schema)
	});

	const { mutateAsync, error, isPending } = authService.useLogin();

	const onSubmit = async data => {
		try {
			await mutateAsync(data);
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<div className="login">
			<form onSubmit={handleSubmit(onSubmit)}>

				<Link to={"/"} className="responsive-back">
					<Icon size={30} name={"arrow-left"} color={"var(--orange)"} />
					Retour à l'accueil
				</Link>

				<RequiredMessage/>
				<Controller
					name="email"
					control={control}
					defaultValue=""
					render={({ field }) => (
						<AuthInput
							field={field}
							error={errors.email}
							type={"email"}
							label={"Identifiant"}
							required={true}
						/>
					)}
				/>

				<Controller
					name="password"
					control={control}
					defaultValue=""
					render={({ field }) => (
						<AuthInput
							field={field}
							error={errors.password}
							type={"password"}
							label={"Mot de passe"}
							required={true}
						/>
					)}
				/>

				<Link
					style={{ marginLeft: "auto", marginBottom: 30, color: "var(--txt-color)", opacity: 0.7 }}
					to={"/forgot"}>
					Mot de passse oublié ?
				</Link>

				{error && (
					<p className="error" style={{ position: "relative", marginBottom: 10 }}>
						{error?.message}
					</p>
				)}

				<FormSubmitButton isPending={isPending}>Connexion</FormSubmitButton>
				<div className="redirect">
					<p>Pas encore de compte ?</p>
					<Link to="/register">Rejoindre la file d’attente</Link>
				</div>
			</form>
		</div>
	);
};

export default Login_Form;
