import React from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import clientService from "../../../services/client/clientService";
import "../form.css";
import { AuthInput } from "../Input/AuthInput";
import FormSubmitButton from "../Input/FormSubmitButton";
import RequiredMessage from "./RequiredMessage";

const LandingPopUpForm = ({ setShow }) => {
	// Schéma de validation avec Yup
	const schema = yup.object().shape({
		structure: yup.string().required("Le nom de l'école est requis."),
		email: yup.string().email("Format d'email invalide").required("L'adresse email est requise."),
		phone: yup.string().optional(),
	});

	const {
		control,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm({
		resolver: yupResolver(schema),
	});

	// Hook pour gérer l'enregistrement
	const { mutateAsync, error, isPending } = clientService.useRegister();

	// Fonction pour soumettre le formulaire
	const onSubmit = async (data) => {
		try {
			await mutateAsync({ ...data, job: "non défini" });
			reset(); // Réinitialise le formulaire après soumission réussie
			if (setShow) setShow(false); // Cache le popup
		} catch (e) {
			// Gérer les erreurs si nécessaire
		}
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<RequiredMessage/>
			<Controller
				name="structure"
				control={control}
				defaultValue=""
				render={({ field }) => (
					<AuthInput
						field={field}
						error={errors.structure}
						type="text"
						label="Nom de votre école"
						required={true}
					/>
				)}
			/>

			<Controller
				name="email"
				control={control}
				defaultValue=""
				render={({ field }) => (
					<AuthInput
						field={field}
						error={errors.email}
						type="email"
						label="Votre adresse e-mail"
						required={true}
					/>
				)}
			/>

			<Controller
				name="phone"
				control={control}
				defaultValue=""
				render={({ field }) => (
					<AuthInput
						field={field}
						error={errors.phone}
						type="text"
						label="Votre numéro de téléphone"
						required={false}
					/>
				)}
			/>


			<div style={{ margin: "auto", display: "flex", flexDirection: "column", width: "fit-content" }}>
				{error && (
					<p className="error-static" style={{ marginBottom: "10px", fontSize: "1rem" }}>
						{error?.message}
					</p>
				)}
				<FormSubmitButton isPending={isPending}>Rejoindre la file</FormSubmitButton>

			</div>

		</form>

	);
};

export default LandingPopUpForm;
